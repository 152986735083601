import React, { useRef, useEffect, useState } from 'react';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import '../mapstyles.css';
import {Marker} from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax



mapboxgl.accessToken =
    'pk.eyJ1IjoidXNlcnBhdiIsImEiOiJja24zZmhxODAwOGpoMnZvMGpnZmtrNnhiIn0.62IOjLEwae3VzgbXme8MNg';
// mine is pk.eyJ1IjoidXNlcnBhdiIsImEiOiJja3AyanpkZGYwNmNjMnVta25scmhob3MxIn0.DUIm9sqf6hql4AYqIeSqBg
// mine default
// default is pk.eyJ1IjoibWFwYm94IiwiYSI6ImNpejY4M29iazA2Z2gycXA4N2pmbDZmangifQ.-g_vE53SD2WrJ6tFX7QHmA

const Map = () => {
    const mapContainerRef = useRef(null);

    const [lng, setLng] = useState(37.61);
    const [lat, setLat] = useState(55.751244);
    const [zoom, setZoom] = useState(9);

    // Initialize map when component mounts
    useEffect(() => {
        const map = new mapboxgl.Map({
            container: mapContainerRef.current,
            style: 'mapbox://styles/mapbox/streets-v11',
            center: [lng, lat],
            zoom: zoom,
            pitch: 0,
            accessToken: 'pk.eyJ1IjoidXNlcnBhdiIsImEiOiJja24zZmhxODAwOGpoMnZvMGpnZmtrNnhiIn0.62IOjLEwae3VzgbXme8MNg'
        });


        map.on('load', () => {

            map.addSource('places', {
                    'type': 'geojson',
                    'data': {
                        'type': 'FeatureCollection',
                        'features': [
                            {
                                'type': 'Feature',
                                'properties': {
                                    'description':
                                        '<strong>Make it Mount Pleasant</strong><p>Make it Mount Pleasant is a handmade and ' +
                                        'vintage market and afternoon of live entertainment and kids activities. 12:00-6:00 p.m.</p>'
                                },
                                'geometry': {
                                    'type': 'Point',
                                    'coordinates': [9.227965, 45.815042]
                                }
                            },
                            {
                                'type': 'Feature',
                                'properties': {
                                    'description':
                                        '<strong>Make it Mount Pleasant</strong><p>Make it Mount Pleasant is a handmade and ' +
                                        'vintage market and afternoon of live entertainment and kids activities. 12:00-6:00 p.m.</p>'
                                },
                                'geometry': {
                                    'type': 'Point',
                                    'coordinates': [9.165451, 45.459905]
                                }
                            },
                            {
                                'type': 'Feature',
                                'properties': {
                                    'description':
                                        '<strong>Make it Mount Pleasant</strong><p>Make it Mount Pleasant is a handmade and ' +
                                        'vintage market and afternoon of live entertainment and kids activities. 12:00-6:00 p.m.</p>'
                                },
                                'geometry': {
                                    'type': 'Point',
                                    'coordinates': [11.553527, 48.179592]
                                }
                            }
                        ]
                    }
                },

                map.addSource('mapbox-dem', {
                    'type': 'raster-dem',
                    'url': 'mapbox://mapbox.mapbox-terrain-dem-v1',
                    'tileSize': 512,
                    'maxzoom': 14
                }),
                map.setTerrain({ 'source': 'mapbox-dem', 'exaggeration': 1.5 }),

                map.addLayer({
                    'id': 'sky',
                    'type': 'sky',
                    'paint': {
// set up the sky layer to use a color gradient
                        'sky-type': 'gradient',
// the sky will be lightest in the center and get darker moving radially outward
// this simulates the look of the sun just below the horizon
                        'sky-gradient': [
                            'interpolate',
                            ['linear'],
                            ['sky-radial-progress'],
                            0.8,
                            'rgba(135, 206, 235, 1.0)',
                            1,
                            'rgba(0,0,0,0.1)'
                        ],
                        'sky-gradient-center': [0, 0],
                        'sky-gradient-radius': 90,
                        'sky-opacity': [
                            'interpolate',
                            ['exponential', 0.1],
                            ['zoom'],
                            5,
                            0,
                            22,
                            1
                        ]
                    }
                })


            );
// Add a layer showing the places.
            map.addLayer({
                'id': 'places',
                'type': 'circle',
                'source': 'places',
                'paint': {
                    'circle-color': '#4264fb',
                    'circle-radius': 6,
                    'circle-stroke-width': 2,
                    'circle-stroke-color': '#ffffff'
                }
            });

// Create a popup, but don't add it to the map yet.
            const popup = new mapboxgl.Popup({
                closeButton: false,
                closeOnClick: false
            });

            map.on('mouseenter', 'places', (e) => {
// Change the cursor style as a UI indicator.
                map.getCanvas().style.cursor = 'pointer';

// Copy coordinates array.
                const coordinates = e.features[0].geometry.coordinates.slice();
                const description = e.features[0].properties.description;

// Ensure that if the map is zoomed out such that multiple
// copies of the feature are visible, the popup appears
// over the copy being pointed to.
                while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                    coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
                }

// Populate the popup and set its coordinates
// based on the feature found.
                popup.setLngLat(coordinates).setHTML(description).addTo(map);
            });

            map.on('mouseleave', 'places', () => {
                map.getCanvas().style.cursor = '';
                popup.remove();
            });
        });







        /////////////////////////

        map.on('move', () => {
            setLng(map.getCenter().lng.toFixed(4));
            setLat(map.getCenter().lat.toFixed(4));
            setZoom(map.getZoom().toFixed(2));
        });

//
// // create DOM element for the marker
//         var el = document.createElement('div');
//         el.id = 'marker';

// // create the marker
//         new mapboxgl.Marker(el)
//             .setLngLat([12.12567, 46.575])
//             .setPopup(popup) // sets a popup on this marker
//             .addTo(map);



        // Clean up on unmount
        return () => map.remove();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            <div className='map-container' ref={mapContainerRef} />
        </div>
    );
};

export default Map;